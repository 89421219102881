<template>
  <el-container @click.capture="show = false" id="scroll-box">
    <el-header style="">
      <div class="header">
        <img src="@/assets/logo.png" class="" alt="" />
        <div class="header-tabs">
          <span
            v-for="(item, index) in tabsList"
            @click="onTabs(index)"
            :style="index == chexTabsIndex ? 'color:#0041F0' : ''"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div class="header-langs">
          <!-- <i class="el-icon-full-screen quan" @click="screen"></i> -->
          <el-dropdown trigger="click">
            <div class="langs">
              <img src="@/assets/langIcon.png" alt="" />
              <p style="width: 65px">{{ langTitle }}</p>
              <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in langList"
                :key="index"
                :style="
                  index == langIndex ? 'color:#000000;font-weight: 600;' : ''
                "
              >
                <p @click="onChangeLang(item, index)">{{ item.title }}</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="button" @click="onTabs(5)">
            {{ $t("headerTop.butTitle") }}
          </div>
          <div class="tabs-img">
            <el-dropdown trigger="click">
              <div class="langs">
                <img src="@/assets/more.png" alt="" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in tabsList"
                  :key="index"
                  :style="index == chexTabsIndex ? 'color:#0041F0' : ''"
                >
                  <p @click="onTabs(index)">{{ item }}</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-header>
     <router-view :key="fefKey" />

    <!-- <div style="height:9000px" id="scroll-bo">

    </div> -->
    <footer class="footer-box" @click.capture="show = false">
      <div class="footer-top">
        <div>
          <P> {{ $t("footer.socializeTitle") }}</P>
          <div>
            <img src="@/assets/facebook.png" alt="" />
            <img src="@/assets/instagram.png" alt="" />
            <img src="@/assets/wx.png" @click="show = true" alt="" />
            <img src="@/assets/dy.png" alt="" />
            <div class="code" v-show="show">
              <img src="@/assets/dy.png" alt="" />
              <span>{{ $t("footer.iconText") }}</span>
            </div>
          </div>
        </div>
        <div>
          <P> {{ $t("footer.contactTitle") }}</P>

          <P> {{ $t("footer.contactAddress") }}</P>
          <P> {{ $t("footer.contactMailbox") }}</P>

          <P> {{ $t("footer.contactPhone") }}</P>
        </div>
        <div>
          <P> {{ $t("footer.aboutTitle") }}</P>

          <P> {{ $t("footer.aboutDescribe") }}</P>
        </div>
      </div>
      <div class="footer-bot">
        <p>
          <span
            v-for="(item, index) in tabsList"
            @click="onTabs(index)"
            :key="index"
            >{{ item }}</span
          >
        </p>
        <p>Copyrights © 2023 All Rights Reserved by HKCBEA</p>
      </div>
    </footer>
  </el-container>
</template>

<script>
// import { use118n1 } from 'vue-i18n'
export default {
  data() {
    return {
      isCollapse: false,
      chexTabsIndex: "99", // 顶部类目切换选中项
      langList: [
        {
          title: "中文简体",
          lang: "zh",
        },
        {
          title: "中文繁体",
          lang: "Czh",
        },
        {
          title: "English",
          lang: "en",
        },
      ],
      langIndex: 0,
      langTitle: "中文简体",
      show: false,
      activeStep: "", // 默认选中的锚点的key值
      offsetTop: 0,
      anchorList: [
        {
          index: 0,
          id: "banner",
        },
        { index: 1, id: "aboutUs" },
        { id: "member", index: 2 },
        { id: "activity", index: 3 },
        { id: "Membershipstyle", index: 4 },
        { id: "JoinUs", index: 5 },
      ],
      fefKey: 1,
    };
  },
  created() {
    console.log(2222222222)
    if (this.$route.fullPath == "/") {
    
      this.chexTabsIndex = 0;
    }

    // this.ongetRoute();
  },

  watch: {
    $route: {
      handler(to, from) {
        
        if (this.$store.state) {
          this.chexTabsIndex=this.$store.state.code
        }
        if (this.$route.fullPath == "/") {
          console.log(this.chexTabsIndex,"-----")
          this.onTabs(this.chexTabsIndex);

          this.$nextTick(() => {
            // 开启滚动条监听
            document.addEventListener("scroll", this.scrollFn, true);
           
            // let elVal = document.getElementsByClassName('el-drawer__body')[0];
            // this.isScroll = elVal.scrollHeight > elVal.clientHeight;
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.langList.forEach((item, index) => {
        if (item.lang == lang) {
          this.langTitle = item.title;
          this.langIndex = index;
        }
      });
    }

    // if (this.$route.fullPath == "/") {
    //   this.$nextTick(() => {
    //     // 开启滚动条监听
    //     document.addEventListener("scroll", this.scroll, true);

    //     // let elVal = document.getElementsByClassName('el-drawer__body')[0];
    //     // this.isScroll = elVal.scrollHeight > elVal.clientHeight;
    //   });
    // }

    // window.addEventListener("scroll", this.scrollFn, true);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollFn, false);
  },

  computed: {
    tabsList() {
      return this.$t("headerTop.tabs");
    },
    scrollFn() {
      // 防抖
      return _.debounce(this.scroll, 100);
    },
  },
 
  methods: {
    onTabs(index) {
      console.log(this.chexTabsIndex , index)
      // if (this.chexTabsIndex == index) {
      //   return;
      // }
      this.chexTabsIndex = index;

      if (this.$route.path != "/") {
        this.$router.push("/");
      }
      switch (index) {
        case 0:
          this.jump("banner");
          break;
        case 1:
          this.jump("aboutUs");
          break;
        case 2:
          this.jump("member");
          break;
        case 3:
          this.jump("activity");
          break;
        case 4:
          this.jump("Membershipstyle");
          break;
        case 5:
          this.jump("JoinUs");
          break;

        default:
          break;
      }
    },
    onChangeLang(item, index) {
      localStorage.setItem("lang", item.lang);
      this.langTitle = item.title;
      this.langIndex = index;
      this.$i18n.locale = item.lang;
      location.replace(location);
      this.fefKey++;
      // location.reload();
      // this.$forceUpdate()
    },

    scroll() {
     
      if (this.$route.fullPath != "/") {
        this.chexTabsIndex = 99;
        return;
      }
      const box = window.scrollY; // document.getElementById("scroll-box");
     
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > window.scrollX) {
        this.offsetTop = 0;
        return;
      }
      let totalH = 0;
      this.anchorList.some((anchor) => {
        let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块

        if (scrollItem) {
          totalH = scrollItem.offsetTop + scrollItem.clientHeight;

          let judge = box < totalH - 140;
          if (judge) {
            this.chexTabsIndex = anchor.index;
            // console.log(anchor.index,"=====")
            return true;
          }
        }
      });
    },
    jump(id) {
     
      this.activeStep = id; // 设置选中的锚点为当前点击的
      this.$nextTick(() => {
        const box = document.getElementById("scroll-box");
        let scrollItem = document.getElementById(id);
       
        // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
        if (scrollItem) {

          this.offsetTop = scrollItem.offsetTop;
            console.log(scrollItem.offsetTop,999999)
          window.scrollTo({ top: scrollItem.offsetTop, behavior: "smooth" });
        }
      });

      // box.scrollTo({
      //   top: scrollItem.offsetTop,
      //   behavior: "smooth",
      // });
    },
  },
};
</script>
<style></style>
<style lang="less" scoped>
:deep {
  .el-header {
    padding: 0;
  }

  .el-header {
    background-color: #fff;
    color: #333;
    line-height: 60px;
    text-align: right;
    border-bottom: solid 1px #e6e6e6;
    font-size: 12px;
    position: sticky;
    top: 0;
    z-index: 99999;
    min-width: 1280px;
  }

  .el-aside {
    color: #333;
  }
}

// &::-webkit-scrollbar {
//   display: none;
// }
.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  .tabs-img {
    display: none;
  }

  img {
    width: 164px;
    height: 33px;
  }

  .header-tabs {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #000000;
    width: 600px;
    cursor: pointer;

    span:hover {
      color: #0041f0;
    }
  }

  .header-langs {
    display: flex;
    align-items: center;
    height: 50px;

    .button {
      width: 84px;
      height: 32px;
      background: #ff5200;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      text-align: center;
      margin-left: 28px;
      cursor: pointer;
    }
  }

  .langs {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;

    // margin-top: 10px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    > p {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      color: #000000;
      margin-right: 5px;
    }
  }
}

.footer-box {
  width: 100%;
  height: 340px;
  background: #0042c1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .footer-top {
    display: flex;
    justify-content: space-between;
    width: 1280px;
    margin: auto;

    div {
      width: 330px;
      position: relative;

      img {
        width: 40px;
        height: 40px;
        // background: #FFFFFF;
        margin-right: 40px;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }

      p:nth-child(1) {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 25px;
      }

      .code {
        width: 144px;
        height: 166px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        padding: 17px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: absolute;
        top: -176px;
        right: 20px;

        span {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }

        > img {
          width: 110px;
          height: 110px;
          background: #d8d8d8;
          border-radius: 8px;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .code ::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border: 4px solid rgba(0, 0, 0, 0);
        position: absolute;
        bottom: -8px;
        left: 8px;
        border-top-color: #fff;
      }
    }
  }

  .footer-bot {
    width: 100%;
    text-align: center;

    p {
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ffffff;

      span {
        margin-left: 20px;
      }
    }

    p:nth-child(1) {
      border-bottom: 1px solid #fff;
    }
  }
}

@media screen and (max-width: 1080px) {
  :deep {
    .el-header {
      width: 108rem;
      padding: 0;
      min-width: 75rem;
      height: 20rem !important;
      position: sticky;
      top: 0;
      z-index: 99999;
    }

    .el-header {
      background-color: #fff;
      color: #333;
      // line-height: 6rem;
    }

    .el-aside {
      color: #333;
    }
  }

  .header {
    width: 108rem;
    position: relative;
    height: 20rem;
    padding: 0 2rem;

    .header-tabs {
      display: none;
    }

    .tabs-img {
      display: flex;
      align-items: center;
      height: 6rem;
      justify-content: center;

      img {
        margin-right: 0;
        margin-left: 6rem;
        width: 6rem;
        height: 6rem;
      }
    }

    .button {
      display: none;
    }
  }

  .footer-box {
    width: 108rem;
    height: 100%;
    background: #0042c1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer-top {
      display: flex;
      flex-direction: column;
      width: 108rem;
      margin: auto;
      padding: 5rem;
      box-sizing: border-box;

      > div {
        position: relative;
        margin-bottom: 8rem;
        width: 98rem;

        img {
          width: 10rem;
          height: 10rem;
          // background: #FFFFFF;
          margin-right: 10rem;
        }

        p {
          font-size: 3.5rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 5rem;
        }

        p:nth-child(1) {
          font-size: 4rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 5.6rem;
          margin-bottom: 3rem;
        }

        .code {
          display: none !important;
          width: 144px;
          height: 166px;
          background: #ffffff;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
          padding: 17px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          position: absolute;
          top: -176px;
          right: 20px;

          span {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
          }

          > img {
            width: 110px;
            height: 110px;
            background: #d8d8d8;
            border-radius: 8px;
            margin-right: 0;
            margin-bottom: 10px;
          }
        }

        .code ::after {
          content: "";
          display: inline-block;
          width: 0;
          height: 0;
          border: 4px solid rgba(0, 0, 0, 0);
          position: absolute;
          bottom: -8px;
          left: 8px;
          border-top-color: #fff;
        }
      }

      > div:nth-child(3) {
        margin-bottom: 0;
      }
    }

    .footer-bot {
      width: 108rem;
      text-align: center;

      p {
        height: 15.4rem;
        line-height: 15.4rem;
        color: #fff;
        font-size: 3.3rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #ffffff;

        span {
          margin: 0;
        }
      }

      p:nth-child(1) {
        display: flex;
        justify-content: space-between;
        padding: 0 5rem;
      }

      p:nth-child(2) {
        font-size: 2.5rem;
      }

      p:nth-child(1) {
        border-bottom: 0.3rem solid #fff;
      }
    }
  }
}
</style>

