// 引入axios
import axios from 'axios'
import Qs from 'qs'
import { Loading,Message } from 'element-ui'
export let baseURL = "http://116.63.134.44/"; // 线上地址
// export let baseURL = "http://192.168.2.111:8041/"; // 测试地址

axios.defaults.timeout = 0;

// 创建axios实例
const httpService = axios.create({
    baseURL: '/api'
});

localStorage.setItem('connect', baseURL + 'backstage/')
localStorage.setItem('imgUrl', baseURL)

// request拦截器
let loadingInst = null
httpService.interceptors.request.use(
    config => {
		// loadingInst = Loading.service({
		//   lock: true,
		//   text: '数据加载中...',
		//   spinner: 'el-icon-loading',
		//   background: 'rgba(0, 0, 0, 0.5)'
		// })
        // // 根据条件加入token-安全携带
        // // if (true) { // 需自定义
        // //     // 让每个请求携带token
        // //     config.headers['User-Token'] = '';
        // // }
        // let contentType = '';
        // if (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'PUT') {
        //     if (config.data.postFileMethod === 'file-upload') {
        //         contentType = 'multipart/form-data;charset=UTF-8';
        //         config.body = config.data;
        //     } else {
        //         contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
        //         config.data = Qs.stringify(config.data)
        //     }
        // } else {
        //     contentType = 'application/json;charset=UTF-8'
        // }
        // config.headers['Content-Type'] = contentType;
        // config.headers['Authorization'] = localStorage.getItem('token')
        return config;
    },
    error => {
        // 请求错误处理
        console.log(error)
    }
)
// respone拦截器    
httpService.interceptors.response.use(
    response => {
		//  loadingInst.close()
        // 统一处理状态
        const res = response.data;
        // 下载文件不执行异常状态处理
        if (response.request.responseType == 'blob') {
            return res;
        }
        // 返回异常
        if (res.code == 200) {
            return res;
        } else if (res.code == 203) {
            Message.error('登录失效，请重新登录')
            location.replace(location.href.split('#')[0] + '#/login');
        } else if (res.code == 202) {
            Message.error(res.msg)
            return res;
        }
    },
    // 处理处理
    error => {
		//  loadingInst.close()
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '错误请求';
                    break;
                case 401:
                    error.message = '未授权，请重新登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源';
                    break;
                case 405:
                    error.message = '请求方法未允许';
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器端出错';
                    break;
                case 501:
                    error.message = '网络未实现';
                    break;
                case 502:
                    error.message = '网络错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网络超时';
                    break;
                case 505:
                    error.message = 'http版本不支持该请求';
                    break;
                default:
                    error.message = `未知错误${error.response.status}`;
            }
        } else {
            error.message = "连接到服务器失败";
        }
        return Promise.reject(error);
    }
)

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {},responseType) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params,
			responseType: responseType || null,
			paramsSerializer: function (params) {
			  return Qs.stringify(params, {
			    arrayFormat: "brackets",
			  });
			},
        }).then(response => {
            resolve(response);
        })
    });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {},responseType) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
			responseType:responseType || null,
        }).then(response => {
            resolve(response);
        })
    });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(method, url, params = {}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: method,
            params: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob'


        }).then(async (data) => {
            const blob = new Blob([data])

            let link = document.createElement('a')
            link.style.display = "none"
            link.href = URL.createObjectURL(blob)

            link.download = new Date().getTime() + '.xlsx'
            document.body.appendChild(link)
            link.click();
        })
    });
}

export default {
    get,
    post,
    fileUpload
}