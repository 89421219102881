import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import "./static/auto-size.js"
import {
  Message
} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import axios from "axios";
import i18n from './lang/index.js'


import {
  get,
  post,
  fileUpload,
  baseURL
} from "./http/request";
Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.prototype.$ajax = axios;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$fileUpload = fileUpload;
Vue.prototype.$baseURL = baseURL;
// Vue.use(ElementUI);
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')