import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from "element-ui";
import layout from "@/views/layout/layout.vue";
Vue.use(VueRouter)
const routes = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login/index.vue')
  // },
  {
    path: "/",
    component: layout,
    children: [
      {
        path: '/',
        name: 'Role',
        keepAlive: true,
        component: () => import('@/views/home/index.vue')
      },
      {
        path:'/activity',
        name:'activity',
        component:()=>import("@/views/activity/index.vue")
      },
      {
        path:'/activity/details',
        name:'activityDetails',
        keepAlive: true,
        component:()=>import("@/views/activity/details.vue")
      },
      {
        path:'/Journal',
        name:'Journal',
        component:()=>import("@/views/Journal/index.vue")
      },
      {
        path:'/journalform',
        name:'journalform',
        component:()=>import("@/views/Journal/journalform.vue")
      },
      {
        path:'/member',
        name:'member',
        component:()=>import("@/views/member/index.vue")
      },
      {
        path:'/success',
        name:'success',
        component:()=>import("@/views/success/index.vue")
      },
      {
        path:'/JoinUs',
        name:'JoinUs',
        component:()=>import("@/views/JoinUs/index.vue")
      },
      
    ],
  },

]

const router = new VueRouter({
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.path == "/login") {
//     next();
//   } else {
//     let token = localStorage.getItem("token");
//     if (token) {
//       next();
//     } else {
//       // alert('你还没有登录，快去登录吧')
//       Message.error("请先登录");
//       next("/login");
//     }
//   }
// });
export default router
