<template>
  <div id="app">
        <keep-alive>
      <router-view />
    </keep-alive>
    

   
  </div>
</template>
<script>
import http from "@/http/request"
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {
    // http.get("/generate/rsa")
    const lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "zh");
    }

  },
  watch: {},
  computed: {},
};
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
</style>
